body {
  padding: 0;
  margin: 0; }

.intro {
  width: 100vw;
  height: 100vh;
  overflow: hidden; }

.absolute-fill, .hanami__intro, .hanami__intro .page-wrapper, .hanami__intro .page, .hanami__intro .page .bg-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden; }

.hanami__intro .page-wrapper {
  width: 50%; }
  .hanami__intro .page-wrapper.right {
    left: 50%; }

.hanami__intro .page {
  left: -100%; }
  .hanami__intro .page .top, .hanami__intro .page .right, .hanami__intro .page .bottom, .hanami__intro .page .left {
    background-color: white;
    position: absolute; }
  .hanami__intro .page .bg-image {
    background-size: cover;
    background-position: center; }
  .hanami__intro .page .top {
    top: -7.4vw;
    left: 0;
    width: 100%;
    height: 50%; }
  .hanami__intro .page .left {
    top: 0;
    left: 0;
    width: 50.1%;
    height: 100%; }
  .hanami__intro .page .right {
    top: 0;
    left: 50%;
    height: 100%;
    width: 50%;
    transform: translateX(14.9vw); }
  .hanami__intro .page .bottom {
    left: 0;
    bottom: -7.4vw;
    height: 50%;
    width: 100%; }
  .hanami__intro .page svg {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 15vw;
    transform: translateY(-50%); }
  .hanami__intro .page.left {
    left: 0%; }
    .hanami__intro .page.left svg {
      left: auto;
      right: 50%; }
    .hanami__intro .page.left .left {
      right: 50%;
      transform: translateX(-14.9vw); }
    .hanami__intro .page.left .right {
      display: none; }

.image-grid {
  margin-left: 20px; }
  .image-grid .image {
    position: relative;
    transform: translateY(20px);
    transition: transform 1s;
    width: calc(33.333333333% - 20px);
    margin-bottom: 20px; }
    .image-grid .image img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      display: block; }
    .image-grid .image .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #fff;
      opacity: 1;
      transition: .5s; }
    .image-grid .image.loaded {
      transform: translateY(0px); }
      .image-grid .image.loaded .overlay {
        opacity: 0; }
